.line-defi-up {
  stroke-dasharray: 50 300;
  transition: all 1s ease;
}
.line-defi-up-1 {
  stroke-dasharray: 80 300;
}
.line-defi-up.active-line-defi-up {
  stroke-dasharray: 1000 300;
  transition: all 2s ease;
}
.line-defi-up-1.active-line-defi-up {
  transition-delay: -0.2s;
}
