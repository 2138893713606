.background-normal {
  transition: fill 0.26s ease;
  fill: transparent;
}
.background-normal.background-active {
  fill: #d9d9d9;
}
.line-normal {
  transition: all 0.5s ease;
  stroke-dasharray: 300 300;
  stroke-dashoffset: 0;
}
.line-normal.line-active {
  stroke-dasharray: 0 300;
  stroke-dashoffset: 0;
}
